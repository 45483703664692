<template>
  <div class="uncompleted-list">
    <form @submit.prevent>
      <b-row class="my-1 px-2" style="gap: 10px">
        <b-col cols="12" sm="auto">
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('search_placeholder')"
          />
        </b-col>
        <b-col>
          <b-button
            variant="outline-secondary"
            @click="clearSearch"
            class="mx-1"
            v-if="searchTerm"
          >
            {{ $t("clear_search") }}
          </b-button>
          <b-button @click="searchHandler" variant="primary" type="submit">
            {{ $t("search") }}
          </b-button>
        </b-col>
      </b-row>
    </form>

    <!-- table -->
    <vue-good-table
      :columns="tableHeader"
      :rows="tableBody"
      styleClass="table-custom-style vgt-table striped condensed"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <!-- Column: Name -->
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label === 'mainImage'" class="text-nowrap">
          {{ $t("g.products/products_list/main_image") }}
        </span>
        <span v-else-if="props.column.label === 'product'" class="text-nowrap">
          {{ $t("g.products/products_list/product") }}
        </span>
        <span
          v-else-if="
            props.column.label === 'actions' &&
            (permission.products.update ||
              permission.products.destroy ||
              permission.products.show)
          "
          class="text-nowrap"
        >
          {{ $t("g.products/products_list/actions") }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template slot="table-row" slot-scope="props">
        <b-link
          :to="{
            name: 'show-product',
            params: {
              catalogSlug: props.row.catalogSlug,
              slug: props.row.slug,
            },
          }"
        >
          <span v-if="props.column.field === 'mainImage'" class="text-nowrap">
            <b-avatar
              style="object-fit: cover"
              variant="info"
              :src="getMainImage(props.row.images)"
            />
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span
            v-else-if="props.column.field === 'product'"
            class="text-nowrap"
          >
            {{ props.row.product }}
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'actions' &&
              (permission.products.update ||
                permission.products.destroy ||
                permission.products.show)
            "
            class="position-absolute"
          >
            <span>
              <b-dropdown
                variant="link"
                class="ddl mt-n2 ml-n5"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="permission.products.update"
                  :to="{
                    name: 'show-product',

                    params: {
                      catalogSlug: props.row.catalogSlug,
                      slug: props.row.slug,
                    },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="permission.products.destroy"
                  @click="onDeleteUser(props.row.slug)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </b-link>
      </template>

      <div slot="emptystate">{{ $t("g.no_data") }}</div>

      <!-- pagination -->
      <template slot="pagination-bottom">
        <div class="mt-2 d-flex justify-content-center flex-wrap">
          <div>
            <b-pagination
              size="md"
              align="center"
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="dataMeta.per_page"
            >
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
  BLink,
  BRow,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import dateTimeInFormat from "@/composables/format-date.js";
import OverlayComponent from "@/components/shared/OverlayComponent";
import AddUsersComponent from "@/components/UsersComponent/addUser/index.vue";

export default {
  name: "UnderReviewList",
  components: {
    AddUsersComponent,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BLink,
    OverlayComponent,
    BRow,
    BCol,
  },
  props: ["tableBody", "dataMeta", "selectedPage"],
  data() {
    return {
      searchTerm: "",
      currentPage: 1,
      isLoading: true,
      overlayLoading: false,
      pageLength: 10,
      formateDate: dateTimeInFormat,
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "mainImage",
          field: "mainImage",
        },
        {
          label: "product",
          field: "product",
        },
        {
          label: "actions",
          field: "actions",
        },
      ],
    };
  },
  created() {
    this.currentPage = this.selectedPage;
    this.tableBody.map((item, index) => {
      item.index = 12 * (this.currentPage - 1) + index + 1;
    });
  },
  computed: {
    permission() {
      return this.$store?.getters?.getUserPermission;
    },
  },
  methods: {
    getMainImage(images) {
      let mainImage;

      if (images?.length > 0) {
        mainImage = images.filter((image) => image.is_main);
      }
      if (mainImage?.length > 0) {
        return mainImage[0]?.path;
      } else {
        return images[0]?.path;
      }
    },
    onDeleteUser(id) {
      this.$emit("deleteProduct", id);
    },
    searchHandler() {
      this.$emit("searchForProduct", this.searchTerm);
    },
    clearSearch() {
      this.searchTerm = "";
      this.$emit("searchForProduct", "");
    },
  },
  watch: {
    currentPage(newValue) {
      this.$emit("updatePage", newValue, this.searchTerm);
    },
    tableBody() {
      this.tableBody.map((item, index) => {
        item.index = 12 * (this.currentPage - 1) + index + 1;
      });
    },
  },
};
</script>

<style lang="scss">
@import "./_index";
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-left-align {
  max-width: 150px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
