<template>
  <div class="add-users-component">
    <!-- <b-button @click="$bvModal.show('add-modal-user')" variant="primary">
      <feather-icon icon="PlusIcon" class="text-white" size="16" />
      {{ $t("g.users/user_list/add_user") }}
    </b-button> -->

    <b-modal
      :title="$t('user')"
      modal-class="add-modal"
      title-class="add-modal-title"
      header-class="add-modal-header"
      footer-class="add-modal-footer"
      :id="`add-modal-user`"
      :ok-title-html="$t('g.ok')"
      :cancel-title="$t('cancel-button')"
      @ok="submitAddUser"
    >
      <validation-observer ref="addUserForm">
        <b-form @submit="submitAddUser">
          <b-row>
            <b-col cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('g.first_name')" label-for="first name">
                <validation-provider
                  #default="{ errors }"
                  name="first name"
                  rules="required"
                >
                  <b-form-input
                    class="input-style"
                    v-model="form_data.first_name"
                    :placeholder="$t('g.first_name')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>

            <b-col cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('g.last_name')" label-for="last name">
                <validation-provider
                  #default="{ errors }"
                  name="last name"
                  rules="required"
                >
                  <b-form-input
                    class="input-style"
                    v-model="form_data.last_name"
                    :placeholder="$t('g.last_name')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>
            <b-col cols="12">
              <!-- Start Input -->
              <b-form-group :label="$t('g.country')" label-for="country">
                <validation-provider
                  #default="{ errors }"
                  name="country"
                  rules="required"
                >
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.country_id"
                  >
                    <b-form-select-option :value="null" disabled>
                      {{ $t("g.addUser_model/form/select_country") }}
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="item in countries"
                      :key="item.text"
                      :value="item.value"
                    >
                      {{ item.text }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>

            <b-col cols="12">
              <!-- Start Dropdown -->
              <b-form-group :label="$t('g.roles')" label-for="roles">
                <validation-provider
                  #default="{ errors }"
                  name="roles"
                  rules="required"
                >
                  <div class="roles-container">
                    <h6
                      v-for="(role, index) in form_data.selectedRole"
                      :key="index"
                    >
                      {{
                        `${role.name}${
                          form_data.selectedRole.length !== index + 1 ? "," : ""
                        }`
                      }}
                    </h6>
                  </div>
                  <b-form-select
                    multiple
                    class="input-style"
                    v-model="form_data.selectedRole"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.users/add_user/select_user')"
                  >
                    <b-form-select-option :value="null">
                      {{ $t("g.users/add_user/select_user_roles") }}
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="role in userRoles"
                      :key="role.id"
                      :value="{ id: role.id, name: role.name }"
                    >
                      {{ role.name }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Dropdown -->
            </b-col>

            <b-col cols="12">
              <!-- Start Input -->
              <b-form-group :label="$t('g.email')" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    type="email"
                    class="input-style"
                    v-model="form_data.email"
                    :placeholder="$t('g.email')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col cols="12">
              <!-- Start Input -->
              <b-form-group :label="$t('g.password')" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required|password"
                >
                  <b-form-input
                    :placeholder="$t('g.password')"
                    type="password"
                    class="input-style"
                    v-model="form_data.password"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col cols="12">
              <!-- Start Phone -->
              <b-form-group :label="$t('g.phone')" label-for="phone">
                <b-input-group>
                  <template #prepend>
                    <vue-country-code
                      ref="vcc"
                      class="input-style"
                      style="width: 80px"
                      :enabledCountryCode="true"
                      @onSelect="onSelectCountry"
                      :preferredCountries="['sa', 'us']"
                    >
                    </vue-country-code>
                  </template>
                  <validation-provider
                    class="input-style phone-input"
                    style="width: calc(100% - 80px)"
                    name="phone"
                    rules="required"
                    #default="{ errors }"
                  >
                    <b-form-input
                      class="input-style"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('g.phone')"
                      v-model="form_data.phone"
                      :value="
                        form_data.phone && form_data.phone.length
                          ? form_data.phone[0].phone
                          : null
                      "
                    />
                    <small class="text-danger">
                      {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                    </small>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
              <!-- End Phone -->
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <OverlayComponent :isLoading="overlayLoading" />
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormSelectOption,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
} from "bootstrap-vue";
import VueCountryCode from "vue-country-code";
import AddModal from "@/components/shared/AddModal/index.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, password, email } from "@validations";

export default {
  name: "AddUsersComponent",
  components: {
    required,
    password,
    email,
    BModal,
    AddModal,
    OverlayComponent,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormSelectOption,
    VueCountryCode,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      overlayLoading: false,
      form_data: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        phone: null,
        country_code: null,
        selectedRole: [],
        country_id: null,
      },
      country: null,
      userRoles: null,
      countries: null,
    };
  },
  created() {
    this.getUserRoles();
    this.getAllCountries();
  },
  methods: {
    handelCountriesData(data) {
      let newCountries = [];
      data.map((country) => {
        newCountries.push({ value: country.id, text: `${country.name}` });
      });
      return newCountries;
    },
    async getAllCountries() {
      try {
        const data = await this.$http.get("web/countries");
        this.countries = this.handelCountriesData(data.data.data);
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    async getUserRoles() {
      try {
        this.overlayLoading = true;
        const res = await this.$http.get(`vendor/roles`);
        const rolesCollector = [];
        for (let role of res.data.data) {
          rolesCollector.push({ name: role.name, id: role.id });
        }
        this.userRoles = rolesCollector;
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    async submitAddUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.addUserForm.validate().then(async (sucess) => {
        if (sucess) {
          try {
            this.overlayLoading = true;
            const formData = new FormData();
            for (let item in this.form_data) {
              const notWanted = ["selectedRole", "country_code"];
              if (!notWanted.includes(item)) {
                if (item === "phone") {
                  formData.append("phone", this.form_data["phone"]);
                  formData.append(
                    "country_code",
                    this.form_data["country_code"]
                  );
                }
                if (item == "password") {
                  formData.append("password", this.form_data["password"]);
                  formData.append(
                    "password_confirmation",
                    this.form_data["password"]
                  );
                }
                formData.append(item, this.form_data[item]);
              }
            }
            this.form_data.selectedRole.map((role, index) => {
              formData.append(`role_id[${index}]`, +role.id);
            });
            const res = await this.$http.post(`vendor/users`, formData);
            if (res.status === 200 || res.status === 201) {
              this.$helpers.makeToast(
                "success",
                res.data.message,
                res.data.message
              );
              this.$bvModal.hide("add-modal-user");
            }
          } catch (err) {
            this.$helpers.handleError(err);
            this.overlayLoading = false;
          } finally {
            this.overlayLoading = false;
          }
        }
      });
    },
    onSelectCountry({ dialCode }) {
      this.form_data.country_code = dialCode;
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
