<template>
  <Loading v-if="isLoading" />
  <b-row v-else class="products-list">
    <b-col class="d-flex mb-2 justify-content-between" cols="12">
      <b-form-group v-slot="{ ariaDescribedby }" class="choose-option">
        <b-form-radio
          v-model="is_request"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          :value="1"
        >
          {{ $t("g.products/products_list/complated_products") }}
        </b-form-radio>
        <b-form-radio
          class="second-radio"
          v-model="is_request"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          :value="2"
        >
          {{ $t("g.products/products_list/uncomplated_products") }}
        </b-form-radio>
        <b-badge v-if="newRequest" variant="danger">{{ newRequest }}</b-badge>

        <b-form-radio
          class="second-radio"
          v-model="is_request"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          :value="3"
        >
          {{ $t("g.products/products_list/wating_products") }}
        </b-form-radio>
        <b-badge v-if="underReviewNewRequest" variant="danger">{{
          underReviewNewRequest
        }}</b-badge>
      </b-form-group>
      <b-button
        v-if="permissions.products.store"
        :to="{ name: 'add-product' }"
        class="btn-add"
        variant="primary"
      >
        <img :src="plusIcon" alt="add product" />
        {{ $t("g.products/products_list/add_product") }}
      </b-button>
    </b-col>
    <b-col cols="12" v-show="is_request === 2">
      <b-card>
        <div
          class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
        >
          <h1 class="m-0 page-title">
            {{ $t("g.products/products_list/uncomplated_products") }}
          </h1>
        </div>
        <!-- table -->
        <uncompleted-list
          @searchForProduct="searchForUnCompletedProduct"
          @deleteProduct="deleteProduct"
          @updatePage="getPageUncompleted"
          :tableBody="uncompletedProducts"
          :dataMeta="uncompletedProductsMeta"
          :selectedPage="uncompletedProductsMeta.current_page"
        />
      </b-card>
    </b-col>
    <b-col cols="12" v-show="is_request === 1">
      <ImportProductsComponent
        v-if="
          globalPermission['categories'] &&
          globalPermission.categories.index &&
          globalPermission['brands'] &&
          globalPermission.brands.index &&
          globalPermission['products'] &&
          globalPermission.products.store
        "
      />
      <b-card>
        <div
          class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
        >
          <h1 class="m-0 page-title">
            {{ $t("g.products/products_list/complated_products") }}
          </h1>
        </div>
        <!-- table -->
        <completed-list
          @searchForProduct="searchForCompletedProduct"
          @deleteProduct="deleteProduct"
          @updatePage="getPageCompleted"
          :tableBody="completedProducts"
          :dataMeta="completedProductsMeta"
          :selectedPage="completedProductsMeta.current_page"
        />
      </b-card>
    </b-col>
    <b-col cols="12" v-show="is_request === 3">
      <b-card>
        <div
          class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
        >
          <h1 class="m-0 page-title">
            {{ $t("g.products/products_list/wating_products") }}
          </h1>
        </div>
        <!-- table -->
        <under-review-list
          @searchForProduct="searchForUnderReviewProduct"
          @deleteProduct="deleteProduct"
          @updatePage="getPageUnderReview"
          :tableBody="underReviewProducts"
          :dataMeta="underReviewProductsMeta"
          :selectedPage="underReviewProductsMeta.current_page"
        />
      </b-card>
    </b-col>
    <overlay-component :isLoading="overlayLoading" />
  </b-row>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormRadio,
  BBadge,
} from "bootstrap-vue";
import dateTimeInFormat from "@/composables/format-date.js";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import CompletedList from "@/components/ProductsComponents/CompletedList/index.vue";
import UncompletedList from "@/components/ProductsComponents/UncompletedList/index.vue";
import UnderReviewList from "@/components/ProductsComponents/UnderReviewList/index.vue";
import ImportProductsComponent from "@/components/ProductsComponents/ImportProductsComponent/index.vue";

export default {
  name: "ProductsList",
  components: {
    BCard,
    BButton,
    Loading,
    BRow,
    BCol,
    OverlayComponent,
    UncompletedList,
    CompletedList,
    BFormGroup,
    BFormRadio,
    BBadge,
    ImportProductsComponent,
    UnderReviewList,
  },
  data() {
    return {
      is_request: 1,
      isLoading: true,
      overlayLoading: false,
      productId: null,
      completedProducts: [],
      uncompletedProducts: [],
      underReviewProducts: [],
      completedProductsMeta: null,
      uncompletedProductsMeta: null,
      underReviewProductsMeta: null,
      tableBody: [],
      newRequest: 0,
      underReviewNewRequest: 0,
      formateDate: dateTimeInFormat,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
    };
  },
  computed: {
    permissions() {
      return this.$store?.getters?.getUserPermission;
    },
  },
  created() {
    this.getProductsList();
  },
  methods: {
    async fetchData(url, pageNumber, search) {
      try {
        const res = search
          ? await this.$http.get(url, {
              headers: {
                "X-Page-Size": 12,
              },
              params: {
                page: pageNumber,
                search,
              },
            })
          : await this.$http.get(url, {
              headers: {
                "X-Page-Size": 12,
              },
              params: {
                page: pageNumber,
              },
            });
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getProductsList() {
      try {
        this.isLoading = true;
        const [completedProducts, uncompletedProducts, underReviewProducts] =
          await Promise.all([
            this.fetchData(
              "vendor/products?pending=false&is_completed=true",
              1
            ),
            this.fetchData(
              "vendor/products?pending=false&is_completed=false",
              1
            ),
            this.fetchData("vendor/products?pending=true", 1),
          ]);

        this.newRequest = uncompletedProducts.meta.total;
        this.underReviewNewRequest = underReviewProducts.meta.total;
        this.completedProductsMeta = completedProducts.meta;
        this.uncompletedProductsMeta = uncompletedProducts.meta;
        this.underReviewProductsMeta = underReviewProducts.meta;

        this.completedProducts.length = 0;
        this.uncompletedProducts.length = 0;
        this.underReviewProducts.length = 0;

        completedProducts.data.map((item) => {
          this.completedProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
        uncompletedProducts.data.map((item) => {
          this.uncompletedProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
        underReviewProducts.data.map((item) => {
          this.underReviewProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async getPageCompleted(pageNumber, search) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "vendor/products?pending=false&is_completed=true",
          pageNumber,
          search
        );
        this.completedProducts = [];
        res.data.map((item) => {
          this.completedProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async getPageUncompleted(pageNumber, search) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "vendor/products?pending=false&is_completed=false",
          pageNumber,
          search
        );
        this.uncompletedProducts = [];
        res.data.map((item) => {
          this.uncompletedProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async getPageUnderReview(pageNumber, search) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "vendor/products?pending=true",
          pageNumber,
          search
        );
        this.underReviewProducts = [];
        res.data.map((item) => {
          this.underReviewProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async handleDeleteProduct(productId) {
      try {
        const request = await this.$http.delete(`vendor/products/${productId}`);
        if ([200, 201].includes(request.status)) {
          await this.getProductsList();
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async deleteProduct(productId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handleDeleteProduct(productId)
        );
        this.overlayLoading = true;
      } catch (error) {
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    async searchForCompletedProduct(searchData) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "vendor/products?pending=false&is_completed=true",
          null,
          searchData
        );
        this.completedProductsMeta = res.meta;
        this.completedProducts = [];
        res.data.map((item) => {
          this.completedProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async searchForUnCompletedProduct(searchData) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "vendor/products?pending=false&is_completed=false",
          null,
          searchData
        );
        this.uncompletedProductsMeta = res.meta;
        this.uncompletedProducts = [];
        res.data.map((item) => {
          this.uncompletedProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async searchForUnderReviewProduct(searchData) {
      try {
        this.overlayLoading = true;
        const res = await this.fetchData(
          "vendor/products?pending=true",
          null,
          searchData
        );
        this.underReviewProductsMeta = res.meta;
        this.underReviewProducts = [];
        res.data.map((item) => {
          this.underReviewProducts.push({
            images: item.images?.length > 0 ? item.images : "",
            product: item.suffix_name,
            vendor: item.vendor_name,
            catalog: item.catalog_name,
            catalogSlug: item.catalog.slug,
            slug: item.slug,
            is_completed: item.is_completed,
          });
        });
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
