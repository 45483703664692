var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-users-component"},[_c('b-modal',{attrs:{"title":_vm.$t('user'),"modal-class":"add-modal","title-class":"add-modal-title","header-class":"add-modal-header","footer-class":"add-modal-footer","id":"add-modal-user","ok-title-html":_vm.$t('g.ok'),"cancel-title":_vm.$t('cancel-button')},on:{"ok":_vm.submitAddUser}},[_c('validation-observer',{ref:"addUserForm"},[_c('b-form',{on:{"submit":_vm.submitAddUser}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.first_name'),"label-for":"first name"}},[_c('validation-provider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"placeholder":_vm.$t('g.first_name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.first_name),callback:function ($$v) {_vm.$set(_vm.form_data, "first_name", $$v)},expression:"form_data.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.last_name'),"label-for":"last name"}},[_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"placeholder":_vm.$t('g.last_name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.last_name),callback:function ($$v) {_vm.$set(_vm.form_data, "last_name", $$v)},expression:"form_data.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.country'),"label-for":"country"}},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.country_id),callback:function ($$v) {_vm.$set(_vm.form_data, "country_id", $$v)},expression:"form_data.country_id"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("g.addUser_model/form/select_country"))+" ")]),_vm._l((_vm.countries),function(item){return _c('b-form-select-option',{key:item.text,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.roles'),"label-for":"roles"}},[_c('validation-provider',{attrs:{"name":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"roles-container"},_vm._l((_vm.form_data.selectedRole),function(role,index){return _c('h6',{key:index},[_vm._v(" "+_vm._s(("" + (role.name) + (_vm.form_data.selectedRole.length !== index + 1 ? "," : "")))+" ")])}),0),_c('b-form-select',{staticClass:"input-style",attrs:{"multiple":"","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.users/add_user/select_user')},model:{value:(_vm.form_data.selectedRole),callback:function ($$v) {_vm.$set(_vm.form_data, "selectedRole", $$v)},expression:"form_data.selectedRole"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t("g.users/add_user/select_user_roles"))+" ")]),_vm._l((_vm.userRoles),function(role){return _c('b-form-select-option',{key:role.id,attrs:{"value":{ id: role.id, name: role.name }}},[_vm._v(" "+_vm._s(role.name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"type":"email","placeholder":_vm.$t('g.email'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.email),callback:function ($$v) {_vm.$set(_vm.form_data, "email", $$v)},expression:"form_data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"placeholder":_vm.$t('g.password'),"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.password),callback:function ($$v) {_vm.$set(_vm.form_data, "password", $$v)},expression:"form_data.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.phone'),"label-for":"phone"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('vue-country-code',{ref:"vcc",staticClass:"input-style",staticStyle:{"width":"80px"},attrs:{"enabledCountryCode":true,"preferredCountries":['sa', 'us']},on:{"onSelect":_vm.onSelectCountry}})]},proxy:true}])},[_c('validation-provider',{staticClass:"input-style phone-input",staticStyle:{"width":"calc(100% - 80px)"},attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.phone'),"value":_vm.form_data.phone && _vm.form_data.phone.length
                        ? _vm.form_data.phone[0].phone
                        : null},model:{value:(_vm.form_data.phone),callback:function ($$v) {_vm.$set(_vm.form_data, "phone", $$v)},expression:"form_data.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}])})],1)],1)],1)],1)],1)],1),_c('OverlayComponent',{attrs:{"isLoading":_vm.overlayLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }